import React, { useState } from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import amHero from '../images/am-news.jpg';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { GatsbyImage } from 'gatsby-plugin-image';

const { getName } = require('country-list');

function filterByCategory(setFilter, cat) {
    setFilter({ cat: cat });
    const quoteContainer = document.querySelector('.news__sort');

    const quotes = quoteContainer.querySelectorAll('.news__sort--btn');
    quotes.forEach(function (_quote) {
        _quote.addEventListener('click', function () {
            quotes.forEach(function (_active_quote) {
                _active_quote.classList.remove('sort--active');
                _quote.classList.add('sort--active');
            });
        });
    });
}

function hideFilterComponent() {
    if (document.getElementById) {
        var fcClose = document.getElementById('blog__sidebar--boxes');
        fcClose.classList.remove('visible__filter');
        fcClose.classList.add('dismiss');
        var fcIconClose = document.getElementById('blog__sidebar--icon');
        fcIconClose.classList.remove('hide__filter');
        var fcIconDivClose = document.getElementById('blog__sidebar--container');
        fcIconDivClose.classList.remove('hide__filter');
    }
    return false;
}

function showFilterComponent() {
    if (document.getElementById) {
        var fcClose = document.getElementById('blog__sidebar--boxes');
        fcClose.classList.add('visible__filter');
        fcClose.classList.remove('dismiss');
        var fcIconClose = document.getElementById('blog__sidebar--icon');
        fcIconClose.classList.add('hide__filter');
        var fcIconDivClose = document.getElementById('blog__sidebar--container');
        fcIconDivClose.classList.add('hide__filter');
    }
    return false;
}

function PressDivs(data, catfilter, datefilter, newsfilter) {
    if (catfilter === 'All' && datefilter === 'All') {
        var pressData = data;
    }

    if (catfilter !== 'All') {
        const catfilterarray = data.filter(
            (x) => x.fieldPressCategory?.[0]?.entity.title === catfilter
        );
        pressData = catfilterarray;
    }

    if (datefilter !== 'All') {
        const datefilterarray = data.filter(function (currentElement) {
            return (
                currentElement.fieldDate?.value.includes(datefilter) ||
                currentElement.fieldEventDate?.value.includes(datefilter)
            );
        });
        pressData = datefilterarray;
    }

    if (catfilter !== 'All' && datefilter !== 'All') {
        const catfilterarray = data.filter(
            (x) => x.fieldPressCategory?.[0]?.entity.title === catfilter
        );
        const datefilterarray = catfilterarray.filter(function (currentElement) {
            return (
                currentElement.fieldDate?.value.includes(datefilter) ||
                currentElement.fieldEventDate?.value.includes(datefilter)
            );
        });
        pressData = datefilterarray;
    }

    let alt = '';
    let pageElements = [];
    pressData.forEach((element) => {
        if (element != null) {
            if (
                element.entityBundle === 'page_event' &&
                (newsfilter.cat === 'All' || newsfilter.cat === 'Events')
            ) {
                let pageEventData = [];

                if (element?.fieldAddress?.locality !== '') {
                    pageEventData.push(element?.fieldAddress?.locality + ',');
                } else {
                    pageEventData.push(<></>);
                }

                pageElements.push(
                    <article className='news__boxes--box news__boxes--box' key={element.title}>
                        <div className='news__box--elements news__box--element'>
                            <div className='news__box--elements--image'>
                                {/*<Img
                                        alt={alt}
                                        fluid={element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp?.fluid}
                                />*/}
                                <GatsbyImage
                                    alt={alt}
                                    image={
                                        element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp
                                            ?.gatsbyImageData
                                    }
                                />
                            </div>
                            <div className='news__box--elements--text'>
                                <div className='news__box--elements--header'>
                                    <div className='news__box--elements--type box__type--calendar'>
                                        <FontAwesomeIcon icon={'calendar-alt'} />
                                    </div>
                                    <div className='news__box--elements--links'>
                                        <ul>
                                            <a href='https://www.facebook.com/activemediausa'>
                                                <li className='news__box--elements--links--facebook'>
                                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                                    <p className='hide-this-element'>Facebook</p>
                                                </li>
                                            </a>
                                            <a href='https://twitter.com/ActiveMedia_USA'>
                                                <li className='news__box--elements--links--twitter'>
                                                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                                                    <p className='hide-this-element'>Twitter</p>
                                                </li>
                                            </a>
                                            <a href='https://www.linkedin.com/company/activemedia'>
                                                <li className='news__box--elements--links--linkedin'>
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'linkedin-in']}
                                                    />
                                                    <p className='hide-this-element'>Linkedin</p>
                                                </li>
                                            </a>
                                        </ul>
                                    </div>
                                </div>
                                <div className='news__box--body'>
                                    <div className='news__box--body--title'>
                                        <h3>
                                            <Link to={element?.entityUrl?.path}>
                                                {element?.title}
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='news__box--body--info'>
                                        <p className='location'>
                                            <FontAwesomeIcon icon={'map-marker-alt'} />{' '}
                                            {pageEventData}{' '}
                                            {element.fieldAddress?.countryCode
                                                ? getName(element.fieldAddress?.countryCode)
                                                : ''}
                                        </p>
                                    </div>
                                    <div className='news__box--body--info'>
                                        <p className='date'>
                                            <FontAwesomeIcon icon={'calendar'} />{' '}
                                            {moment(element.fieldEventDate.startDate).format(
                                                'dddd, D MMMM, Y'
                                            )}{' '}
                                            {element.fieldEventDate.endDate ? 'to' : ''}{' '}
                                            {element.fieldEventDate.endDate
                                                ? moment(element.fieldEventDate.endDate).format(
                                                      'dddd, D MMMM, YYYY'
                                                  )
                                                : ''}
                                        </p>
                                    </div>
                                    <div className='news__box--body--category'>
                                        <Link to='#'>
                                            {element?.fieldPressCategory?.[0]?.entity.title}
                                        </Link>
                                    </div>
                                    <div className='news__box--body--text'>
                                        <HtmlParser
                                            html={
                                                element?.body?.processed !== undefined
                                                    ? element.body.processed.substring(0, 450)
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='news__box--body--btn'>
                                        <Link to={element?.entityUrl?.path}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                );
            }
            if (
                element.entityBundle === 'page_news' &&
                (newsfilter.cat === 'All' || newsfilter.cat === 'News')
            ) {
                pageElements.push(
                    <article className='news__boxes--box news__boxes--box' key={element.title}>
                        <div className='news__box--elements news__box--element'>
                            <div className='news__box--elements--image'>
                                {/*<Img
                                    alt={alt}
                                    fluid={element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp?.fluid}
                            />*/}
                                <GatsbyImage
                                    alt={alt}
                                    image={
                                        element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp
                                            ?.gatsbyImageData
                                    }
                                />
                            </div>
                            <div className='news__box--elements--text'>
                                <div className='news__box--elements--header'>
                                    <div className='news__box--elements--type box__type--newspaper'>
                                        <FontAwesomeIcon icon={['far', 'newspaper']} />
                                    </div>
                                    <div className='news__box--elements--links'>
                                        <ul>
                                            <a href='https://www.facebook.com/activemediausa'>
                                                <li className='news__box--elements--links--facebook'>
                                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                                    <p className='hide-this-element'>Facebook</p>
                                                </li>
                                            </a>
                                            <a href='https://twitter.com/ActiveMedia_USA'>
                                                <li className='news__box--elements--links--twitter'>
                                                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                                                    <p className='hide-this-element'>Twitter</p>
                                                </li>
                                            </a>
                                            <a href='https://www.linkedin.com/company/activemedia'>
                                                <li className='news__box--elements--links--linkedin'>
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'linkedin-in']}
                                                    />
                                                    <p className='hide-this-element'>Linkedin</p>
                                                </li>
                                            </a>
                                        </ul>
                                    </div>
                                </div>
                                <div className='news__box--body'>
                                    <div className='news__box--body--title'>
                                        <h3>
                                            <Link to={element?.entityUrl?.path}>
                                                {element?.title}
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='news__box--body--info'>
                                        <p className='date'>
                                            <FontAwesomeIcon icon={'calendar'} />{' '}
                                            {moment(element.fieldDate.value).format('MM/DD/Y')}
                                        </p>
                                    </div>
                                    <div className='news__box--body--category'>
                                        <Link to='#'>
                                            {element?.fieldPressCategory?.[0]?.entity.title}
                                        </Link>
                                    </div>
                                    <div className='news__box--body--text'>
                                        <HtmlParser
                                            html={
                                                element?.body?.processed !== undefined
                                                    ? element.body.processed.substring(0, 450)
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='news__box--body--btn'>
                                        <Link to={element?.entityUrl?.path}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                );
            }
        }
    });
    return <div className='news__boxes'>{pageElements}</div>;
}

function Press({ data, location }) {
    const alldata = data.drupal.nodeQuery.entities;
    const mixdata = alldata.filter((x) => x !== null);

    const [pageFilter, setPageFilter] = useState(mixdata);
    const [catFilter, setCatFilter] = useState('All');
    const [dateFilter, setDateFilter] = useState('All');
    const [filter, setFilter] = useState({ cat: 'All' });

    if (typeof window !== 'undefined' && window.document) {
        if (typeof document !== `undefined`) {
            if (location.pathname === '/blog') {
                {
                    function getScrollTop() {
                        if (typeof window.pageYOffset !== 'undefined') {
                            // Most browsers
                            return window.pageYOffset;
                        }

                        var d = document.documentElement;
                        if (typeof d.clientHeight !== 'undefined') {
                            // IE in standards mode
                            return d.scrollTop;
                        }

                        // IE in quirks mode
                        return document.body.scrollTop;
                    }

                    if (location.pathname === '/press') {
                        window.onscroll = function () {
                            if (document.getElementById('blog__sidebar--container') !== null) {
                                var box = document.getElementById('blog__sidebar--container');
                                var scroll = getScrollTop();

                                if (scroll <= 600) {
                                    box.style.top = '600px';
                                } else {
                                    box.style.top = scroll - 300 + 'px';
                                }
                            } else {
                                return false;
                            }
                        };
                    }
                }
            }
        }
    }

    return (
        <Layout>
            <Seo title='Press' />
            <div className='hero__image press'>
                <figure>
                    <img src={amHero} alt='ActiveMedia hero'></img>
                </figure>
                <div className='hero__pages'>
                    <div className='hero__news--center'>
                        <h1>News & Events</h1>
                        <p>
                            <span>Stay Up to Date on The Latest News & Events.</span>
                        </p>
                        <div className='news__signup'>
                            <form>
                                <div className='news__sighup--input'>
                                    <input type='text' size='25' maxLength='128'></input>
                                </div>
                                <div className='news__sighup--btn'>
                                    <button
                                        onSubmit={() => {
                                            //console.log('submit')
                                        }}
                                        name='signup-submit'
                                        value='Sign Up'>
                                        Sign Up
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div id='mobile__hero--btn' className='hero__btn news__hero--btn press-hero-btn'>
                    <Link to='#breadcrumb'>Find Out More</Link>
                </div>
            </div>
            <div className='breadcrumb__container blog__breadcrumb--container' id='breadcrumb'>
                <div className='breadcrumb__container--box'>
                    <div className='breadcrumb'>
                        <Link to='/'>Home </Link>/<span> News & Events</span>
                    </div>
                </div>
            </div>
            <div className='main__container press-main__container'>
                <section id='blog__sidebar--container' className='blog__sidebar'>
                    <div
                        id='blog__sidebar--icon'
                        className='blog__sidebar--icon'
                        aria-hidden='true'
                        onKeyDown={() => showFilterComponent()}
                        onClick={() => showFilterComponent()}>
                        <FontAwesomeIcon icon={['fas', 'filter']} />
                    </div>
                    <div id='blog__sidebar--boxes' className='blog__sidebar--boxes'>
                        <div
                            id='blog__sidebar--close'
                            className='blog__sidebar--close'
                            aria-hidden='true'
                            onKeyDown={() => hideFilterComponent()}
                            onClick={() => hideFilterComponent()}>
                            <FontAwesomeIcon icon={['fas', 'times']} />
                        </div>
                        <div className='blog__sidebar--boxes--box'>
                            <div className='blog__sidebar--categories'>
                                <div className='blog__sidebar--title'>
                                    <h2>Categories</h2>
                                </div>
                                <div className='blog__sidebar--links'>
                                    <button
                                        onClick={() => setCatFilter('Web Design & Development')}>
                                        Web Design & Development
                                    </button>
                                    <button onClick={() => setCatFilter('Online Marketing')}>
                                        Online Marketing
                                    </button>
                                    <button onClick={() => setCatFilter('Startup')}>Startup</button>
                                </div>
                            </div>
                            <div className='blog__sidebar--archive'>
                                <div className='blog__sidebar--title'>
                                    <h2>Archive</h2>
                                </div>
                                <div className='blog__sidebar--links'>
                                    <button onClick={() => setDateFilter('2022')}>2022</button>
                                    <button onClick={() => setDateFilter('2021')}>2021</button>
                                    <button onClick={() => setDateFilter('2020')}>2020</button>
                                    <button onClick={() => setDateFilter('2019')}>2019</button>
                                    <button onClick={() => setDateFilter('2018')}>2018</button>
                                    <button onClick={() => setDateFilter('2017')}>2017</button>
                                    <button onClick={() => setDateFilter('2016')}>2016</button>
                                    <button onClick={() => setDateFilter('2015')}>2015</button>
                                    <button onClick={() => setDateFilter('2014')}>2014</button>
                                    <button onClick={() => setDateFilter('2013')}>2013</button>
                                    <button onClick={() => setDateFilter('2012')}>2012</button>
                                    <button onClick={() => setDateFilter('2011')}>2011</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='news__container'>
                    <div className='news__fafaicon'>
                        <div className='news__fafaicon--container'>
                            <div className='news__fafaicon--details'>
                                <FontAwesomeIcon icon={'bullhorn'} />
                            </div>
                        </div>
                    </div>
                    <h2>What's happening in the world of online marketing and web development.</h2>
                    <div className='news__sort'>
                        <div className='news__sort--btn sort--active'>
                            <button value='All' onClick={() => filterByCategory(setFilter, 'All')}>
                                All
                            </button>
                        </div>
                        <div className='news__sort--btn'>
                            <button
                                value='News'
                                onClick={() => filterByCategory(setFilter, 'News')}>
                                News
                            </button>
                        </div>
                        <div className='news__sort--btn'>
                            <button
                                value='Events'
                                onClick={() => filterByCategory(setFilter, 'Events')}>
                                Events
                            </button>
                        </div>
                    </div>
                    <div className='blog__main--filter-options'>
                        <div
                            className={`${catFilter.toLowerCase()} blog__main--filter-options--cat`}>
                            <button onClick={() => setCatFilter('All')}>
                                {catFilter} <FontAwesomeIcon icon={['fas', 'times']} />
                            </button>
                        </div>
                        <div
                            className={`${dateFilter.toLowerCase()} blog__main--filter-options--date`}>
                            <button onClick={() => setDateFilter('All')}>
                                {dateFilter} <FontAwesomeIcon icon={['fas', 'times']} />
                            </button>
                        </div>
                    </div>
                    <div>{PressDivs(pageFilter, catFilter, dateFilter, filter)}</div>
                </section>
            </div>
        </Layout>
    );
}

export default Press;

export const data = graphql`
    {
        drupal {
            nodeQuery(
                filter: {
                    conditions: { field: "type", value: "page_news", operator: EQUAL }
                    groups: {
                        conditions: { field: "type", value: "page_event", operator: EQUAL }
                        groups: { conditions: { field: "status", value: "1", operator: EQUAL } }
                        conjunction: AND
                    }
                    conjunction: OR
                }
                limit: 999999
                sort: { field: "created", direction: DESC }
            ) {
                entities {
                    entityBundle
                    entityUrl {
                        path
                    }
                    ... on Drupal_NodePageEvent {
                        nid
                        uuid
                        title
                        fieldAddress {
                            countryCode
                            postalCode
                            givenName
                            locality
                        }
                        body {
                            processed
                        }
                        fieldEventDate {
                            endDate
                            startDate
                            endValue
                            value
                        }
                        fieldPressCategory {
                            entity {
                                title
                            }
                        }
                        fieldOfficialEventWebsite {
                            url {
                                path
                            }
                        }
                        fieldMedia {
                            notStyledImage {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            entity {
                                uuid
                                url
                            }
                            url
                        }
                    }
                    ... on Drupal_NodePageNews {
                        nid
                        uuid
                        title
                        body {
                            processed
                            summaryProcessed
                        }
                        fieldAttachment {
                            entity {
                                uuid
                            }
                        }
                        fieldDate {
                            date
                            value
                        }
                        fieldFile {
                            entity {
                                uuid
                            }
                        }
                        fieldMedia {
                            entity {
                                uuid
                                url
                            }
                            notStyledImage {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            url
                        }
                        fieldNewsType
                        fieldPressCategory {
                            entity {
                                title
                            }
                        }
                        fieldUrl {
                            url {
                                path
                            }
                        }
                    }
                }
            }
        }
    }
`;
